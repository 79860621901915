<template>
  <div>
    <div class="columns is-mobile">
      <div class="column">
        <p class="subtitle has-text-primary">Franchise AutoCheck Certificate</p>
      </div>
      <div class="column is-narrow">
        <a class="has-text-danger" @click="cancel">Cancel</a>
      </div>
    </div>
    <div class="field">
      Let's customise your certificate. Choose the style of the certificate
      along with the email you would like to send it to.
    </div>
    <div class="box">
      <div class="field">
        <div class="has-text-link">
          Select the style of your certificate
        </div>
      </div>
      <MultiSelect
        v-model="selected"
        :options="franchises"
        placeholder="Select or start typing"
        :disabled="franchises.length <= 1"
        :show-labels="false"
        :hide-selected="true"
        :allow-empty="false"
        label="name"
        track-by="id"
        @input="trackSelected"
      >
        <span slot="noResult">No franchises matching your search</span>
      </MultiSelect>
    </div>
    <div class="box content">
      <div class="field">
        <div class="control">
          <div class="columns filter-menu-dropdown">
            <div class="column has-text-link">
              Certificate will download - tick here to also receive it via email
            </div>
            <div class="column is-narrow">
              <span class="radio-tick">
                <checkbox v-model="sendEmail" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Email"
            v-model="email"
            :disabled="!sendEmail"
          />
        </div>
      </div>
    </div>
    <div class="buttons is-right">
      <button class="button is-text" @click="goBack">Go back</button>
      <button
        class="button is-soft is-rounded"
        :class="{ 'is-loading': $wait.is('downloading-franchise') }"
        :disabled="!email && sendEmail"
        @click="generateFranchisePdf"
      >
        <span class="icon">
          <i class="fal fa-file-certificate" />
        </span>
        <span>Download</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as pdf from '../../services/pdfs'
import { getBatchPdfs, getBatchPdfData } from '@/services/PdfService'
export default {
  name: 'AutoCheckCertificateFranchises',
  components: {
    MultiSelect: () => import('vue-multiselect')
  },
  props: {
    franchiseData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selected: null,
    email: '',
    sendEmail: false
  }),
  created() {
    this.selected = this.franchiseData.franchises[0]
    this.email = this.user.email
  },
  computed: {
    ...mapGetters('autocheck', ['grid', 'specs', 'response', 'hasFuelData']),
    ...mapGetters('auth', ['user']),
    franchises() {
      const franchises = this.franchiseData.franchises
      return franchises.sort()
    }
  },
  watch: {
    sendEmail: 'trackEmail'
  },
  methods: {
    async generateFranchisePdf() {
      const {
        specs,
        grid,
        response,
        selected,
        hasFuelData,
        email,
        sendEmail
      } = this
      this.track('download_franchise_pdf', { franchise: selected.name })

      try {
        this.$wait.start('downloading-franchise')

        const storeData = { specs, grid, response }
        const franchisePdf = await pdf.getFranchisePdf(selected, storeData)
        const franchisePdfBase64 = await getBatchPdfData(franchisePdf)
        const pdfs = [franchisePdf]

        if (sendEmail) pdf.emailPdf(franchisePdfBase64, email)

        if (hasFuelData) pdfs.push(pdf.getFuelEconomyPdf())
        getBatchPdfs(pdfs, franchisePdf.vrm, 'file')

        this.$modal.close()
      } catch {
        this.$notify('Failed to export PDF')
      } finally {
        this.$wait.end('downloading-franchise')
      }
    },
    trackSelected({ name }) {
      this.track('change_franchise', { franchise: name })
    },
    trackEmail() {
      this.track(`email_${this.sendEmail ? 'check' : 'uncheck'}`)
    },
    cancel() {
      this.track('cancel')
      this.$modal.close()
    },
    goBack() {
      this.track('back')
      this.$emit('back')
    },
    track(action, data) {
      this.$mxp.track(`autocheck_franchises_modal_${action}`, data)
    }
  }
}
</script>
